import React from 'react';
import styled from 'styled-components';
import videoThumbnail from './video_thumbnail.png';
// import videoPlaybutton from './video_playbutton.svg';

const StyledIframeWrapper = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`;

const MultiStyledIframeWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 200px;
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoPreviewWrapper = styled.div`
  position: relative;
`;

const VideoPlayer = ({ videoLink }) => (
  <StyledIframeWrapper>
    <StyledIframe src={videoLink} frameBorder="0" allowFullScreen />
  </StyledIframeWrapper>
);

export const StyledCaption = styled.span`
  text-align: start;
  font-size: small;
  line-height: initial;
  margin-bottom: 20px;
  font-size: 18px;
`;

const MultiVideoPlayer = ({ videoLink, caption }) => (
  <div>
    <MultiStyledIframeWrapper>
      <StyledIframe src={videoLink} frameBorder="0" allowFullScreen />
    </MultiStyledIframeWrapper>
    {caption && <StyledCaption>{caption}</StyledCaption>}
  </div>
);

const VideoThumbnail = styled.img`
  height: 210px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 5px;
`;

export const VideoPreview = () => (
  <VideoPreviewWrapper>
    <VideoThumbnail alt="Karista video" src={videoThumbnail} />
  </VideoPreviewWrapper>
);

export { VideoPlayer, MultiVideoPlayer };
