import React from 'react';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, {ThemeProvider} from 'styled-components';

import mediaQueries from '../../styles/mediaQueries';
import * as adminActions from '../Admin/actions';
import * as providerActions from '../Providers/actions';
import Campaign from './components/Campaign';
import Hero from './components/Hero';
import Values from './components/Values';
import Breakpoint from '../../components/Breakpoint';
import Footer from '../../components/Footer';
import { Grid, GridItem } from '../../components/Grid';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Search from './components/Search';
import Theme from '../../components/Theme';
import { LandingPixel } from '../../components/Trackers';
import { getNewCampaign } from '../../util/local-storage';
import {themePrimary, colors, themeLightGrey} from '../../styles/constants';
import Section from '../../components/Section';

import { DISPLAY_INTRO_VIDEO } from './constants';

import { AdminActions } from '../Admin/actions';
import { ProvidersActions } from '../Providers/actions';
import WhatWeDo from "./components/WhatWeDo";
import { GammaHeading } from '../../components/Typography';
import CampaignHome from "../CampaignHome";
import { Card, BlackInfoText } from '../../../src/apps/Providers/containers/styles'
import callIcon from '../../../src/apps/Providers/containers/static/icon-phone.png';


export const CardInfoText = styled(BlackInfoText)`
  color: ${colors.darkPurple};
  padding: 5px;
  white-space: nowrap;
  font-size: 25px;
`;


const iconStyle = {
  width: '20px',
  height: '20px',
};

const StyledSection = styled(Section)`
  text-align: center;
  ${mediaQueries.mobile`
    padding-top: 24px;
  `};
`;


const CallUsSection = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 56px;
  ${props => ({...props.theme})};
`;

const CallUsHeading = styled(GammaHeading)`
  line-height: 28px;
  margin: 0;
`;

const StyledPage = styled(Page)`
  padding-bottom: 0;
`;

interface HomeProps extends RouteComponentProps {
  actions: ProvidersActions,
  adminActions: AdminActions,
};

const Banner = styled.div`
  color: ${colors.darkPurple};
  background: ${colors.orange};
  text-align: center;
  padding: 10px 10px;
`;

export const CallLink = styled.a`
  color: ${colors.darkPurple};
  text-decoration: none;
`;

const Home = (props: HomeProps) => {
  const newCampaign = String(getNewCampaign())

  const HeroDesktop = () => {
    
      return (
      <div>
        <StyledSection>
          <Grid wrap="wrap" alignItems="flex-end">
            <GridItem mobile={12} tablet={12}>
              <Hero />
            </GridItem>

          </Grid>
        </StyledSection>
      </div>
    );
  }

  const HeroMobile = () => {
    return (
      <div>
        <StyledSection>
          <Grid wrap="wrap" alignItems="flex-end">
            <GridItem mobile={12} tablet={6}>
              <Campaign render={campaign => <Hero />} />
            </GridItem>
          </Grid>
        </StyledSection>
      </div>
    );
  }

  return (
    newCampaign ? (
      <CampaignHome {...props} campaign={newCampaign}/>
    ) : (
      <Theme theme={themePrimary}>
        <LandingPixel />
        <Navigation isHome />
        <Banner>
          <b>Call us on <CallLink href="tel:+61485972676">0485 972 676</CallLink> Monday to Friday 9am to 5pm AEST</b>
        </Banner>
        <StyledPage>
          {DISPLAY_INTRO_VIDEO ? (
            <Breakpoint>
            {breakpoint => (
              breakpoint === 'desktop' ? <HeroDesktop /> : <HeroMobile />
            )}
          </Breakpoint>
          ) : (
            <StyledSection>
              <Campaign render={campaign => <Hero />} />
            </StyledSection>
          )}
          <Breakpoint>
            {breakpoint => (
              breakpoint === 'desktop' ? null : <Values />
            )}
          </Breakpoint>
          
          <Search {...props} />
          <WhatWeDo />
          <ThemeProvider theme={themeLightGrey}>
            <CallUsSection>
              <Card style={{ marginBottom: '16px', marginTop: '10px' }}>
                <img src={callIcon} alt="Call icon" style={iconStyle}/>
                <CallLink href="tel:+61485972676">
                  <CardInfoText><b>0485 972 676</b></CardInfoText>
                </CallLink>
              </Card>
              <CallUsHeading>
                Call us Monday to Friday <br />
                9am to 5pm AEST
              </CallUsHeading>
            </CallUsSection>
          </ThemeProvider>
        </StyledPage>
        <Footer />
      </Theme>
    )
  );  
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(providerActions, dispatch),
  adminActions: bindActionCreators(adminActions, dispatch)
});

export default connect(() => ({}), mapDispatchToProps)(Home);
