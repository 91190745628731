import React from 'react';
import styled from 'styled-components';

import mediaQueries from '../../../../styles/mediaQueries';
import { AlphaHeading, BetaHeading } from '../../../../components/Typography';
import { HomePageLogoVertical } from '../../../../components/Logo';
import Breakpoint from '../../../../components/Breakpoint';

const StyledTitle = styled(AlphaHeading)`
  max-width: 500px;
  margin-left: 24px;
  margin-bottom: 0;
  
  ${mediaQueries.mobile`
    max-width: 700px;
    line-height: 36px;
    font-size: 26px;
    margin-left: 0px;
    margin-top: 6px;
  `};
`;

const AdditionalStyledTitle = styled(BetaHeading)`
  max-width: 500px;
  margin-left: 24px;
  margin-top: 24px;
  margin-bottom: 0;
  color: #EBCCF0;
  
  ${mediaQueries.mobile`
    line-height: 36px;
    font-size: 22px;
    margin-top: 12px;
  `};
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${mediaQueries.mobile`
    line-height: 36px;
    font-size: 22px;
    margin-top: 12px;
  `};
`;

const Hero = () => {
  const defaultContent = 'Search for services.';
  const additionalContent = 'Free to NDIS participants and HCP recipients.'
  const mobileContent = 'Search for disability or home care services.'
  return (
    <HeroSection>
      <HomePageLogoVertical /> 
      <Breakpoint>
        {breakpoint => (
          breakpoint === 'desktop' ? 
          <div>
            <StyledTitle>{defaultContent}</StyledTitle>
            <AdditionalStyledTitle>{additionalContent}</AdditionalStyledTitle>
          </div> : 
          <StyledTitle>{mobileContent}</StyledTitle>
        )}
      </Breakpoint>  
    </HeroSection>
  );
}

export default Hero;
