import styled from "styled-components";
import Section from "../../../../components/Section";
import { AlphaHeading } from "../../../../components/Typography";
import {colors} from "../../../../styles/constants";
import mediaQueries from "../../../../styles/mediaQueries";
import DataProtection from "./icon-data-protect-guarantee.svg"
import ReactPlayer from 'react-player';
import Button from '../../../../components/Button';

const cardStackBreakpoint = '720px';

export const InfoText = styled.span`
  line-height: 24px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 80%;
`;

export const HeaderSection = styled(Section)`
  display: flex;
  flex-direction: row;
  text-align: left;

  ${mediaQueries.mobile`
    width: auto;
    text-align: center;
    flex-direction: column;
  `};
`;

export const HeaderInnerSection = styled.div`
  max-width: 420px;
  margin-right: 20px;
`;

export const HeaderHeading = styled(AlphaHeading)`
  margin-bottom: 12px;
`;

export const HeaderText = styled(BlackInfoText)`
  ${mediaQueries.mobile`
    padding: 0;
  `};
`;

export const ConfidentialitySection = styled(Section)`
  padding-top: 36px;
  padding-bottom: 56px;
  display: flex;
  @media (max-width: ${cardStackBreakpoint}) {    
    display: block;
    text-align: center;
  }
`;

export const DataProtectionIcon = styled.img.attrs({src: DataProtection})`
  padding-right: 16px;
  width: 120px;

  @media (max-width: ${cardStackBreakpoint}) {
    padding-right: 0;
    padding-bottom: 16px
  }
`;

export const StyledPlayerWrapper = styled.div`
  position: relative;
  max-width: 720px;
  margin: 0 auto;
  top: 110%;
`;

export const StyledReactPlayer = styled(ReactPlayer)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  z-index: 9999;
  top: 200px;
  right: 40px;
  padding: 0 16px;
  min-height: 30px;
  ${mediaQueries.mobile`
    display: none;
  `};
`;