import React from 'react';
import Theme from '../../../../components/Theme';
import {themeLightGrey, themeSecondary} from "../../../../styles/constants";
import WhatWeDoCard from "../../../../components/WhatWeDoCard";
import { VideoPreview } from '../../../../components/VideoPlayer';
import Breakpoint from '../../../../components/Breakpoint';

import {
    BlackInfoText,
    DataProtectionIcon,
    ConfidentialitySection,
    HeaderHeading,
    HeaderSection,
    HeaderText,
    StyledPlayerWrapper,
    StyledReactPlayer,
    StyledButton,
    HeaderInnerSection
} from "./style";


const WhatWeDo = () => {
    const [playing, setPlaying] = React.useState<boolean>(false);
    const videoUrl = 'https://player.vimeo.com/video/1032152576?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    const mobileVideoUrl = 'https://player.vimeo.com/video/1047331410?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=5847';

    const modalStyle: React.CSSProperties = playing
      ? {
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(62, 15, 72, 0.9)',
          zIndex: 999,
          top: '80%',
          left: '0px'
        }
      : { display: 'relative' };
    const modalDesktopStyle = playing
      ? {
          position: 'absolute',
          background: 'transparent'
        }
      : { display: 'none' };
    const wrapperStyle = playing
      ? {
          top: '10%'
        }
      : {};


    return (
        <Theme theme={themeLightGrey}>
            <Breakpoint>
                {breakpoint => (
                    breakpoint === 'desktop' ? 
                    <HeaderSection>
                        <HeaderInnerSection>
                            <HeaderHeading>How does Karista work?</HeaderHeading>
                            <HeaderText>
                                Karista provides a <b>free</b>, independent service connecting you with disability and home care
                                services, therapists and support workers based on your personal needs and goals. Our Client
                                Services team are experienced in finding and connecting NDIS and Home Care Package (HCP)
                                participants to supports with availability.
                            </HeaderText>
                        </HeaderInnerSection>
                        <div style={modalStyle}>
                            <StyledPlayerWrapper
                                style={wrapperStyle}
                                onClick={() => {
                                    setPlaying(!playing);
                                }}
                                >
                                <StyledButton
                                    style={!playing ? { display: 'none' } : {top: '10%'}}
                                    onClick={() => {
                                        setPlaying(!playing);
                                    }}
                                >
                                    X
                                </StyledButton>
                                <StyledReactPlayer
                                    width="100%"
                                    style={modalDesktopStyle}
                                    url={videoUrl}
                                    playing={playing}
                                    config={{
                                        vimeo: {
                                            playerOptions: { playsinline: false },
                                            preload: true
                                        }
                                    }}
                                    onPlay={() => setPlaying(true)}
                                    onPause={() => setPlaying(false)}
                                    playsinline
                                    onError={() => console.error('Vimeo Player Error')} // eslint-disable-line
                                />
                                {!playing ? <VideoPreview /> : null}
                            </StyledPlayerWrapper>
                        </div>
                    </HeaderSection> : 
                    <HeaderSection>
                        <StyledPlayerWrapper>
                            <StyledReactPlayer
                                width="100%"
                                height="320px"
                                style={{
                                    background: 'transparent'
                                }}
                                url={mobileVideoUrl}
                                playing={playing}
                                config={{
                                    vimeo: {
                                        preload: true
                                    }
                                }}
                                onPlay={() => setPlaying(true)}
                                onPause={() => setPlaying(false)}
                                onError={() => console.error('Vimeo Player Error')} // eslint-disable-line
                            />
                        </StyledPlayerWrapper>
                        <HeaderInnerSection>
                            <HeaderHeading>How does Karista work?</HeaderHeading>
                            <HeaderText>
                                Karista provides a <b>free</b>, independent service connecting you with disability and home care
                                services, therapists and support workers based on your personal needs and goals. Our Client
                                Services team are experienced in finding and connecting NDIS and Home Care Package (HCP)
                                participants to supports with availability.
                            </HeaderText>
                        </HeaderInnerSection>
                    </HeaderSection> 
                )}
            </Breakpoint>  

            <WhatWeDoCard />
            <Theme theme={themeSecondary}>
                <ConfidentialitySection>
                    <DataProtectionIcon alt="Lock icon" />
                    <BlackInfoText>
                    We prioritise data security with end-to-end encryption, ensuring your information stays private and secure. 
                    We guarantee your data will never be shared with third parties, 
                    maintaining confidentiality and protecting your privacy at all times.
                    </BlackInfoText>
                </ConfidentialitySection>
            </Theme>
        </Theme>
    );
}

export default WhatWeDo;